import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Col, Container, Row } from "react-bootstrap";

const ImpressumPage = () => {
  return (
    <Layout>
      <Seo
        title="Impressum"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <Container className="py-5">
        <Row className="justify-content-center">
          <Col>
            <h1 className="h2 section-title">Impressum</h1>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">Inhaltliche Verantwortung</h2>
            <p>
              Telefon: <a
                href="tel:+41 79 329 25 87"
                className="text-secondary"
              >079 329 25 87</a>
              <br />
              <a
                href="mailto:praxis@prenosil.ch"
                className="text-secondary"
              >
                praxis@prenosil.ch
              </a>
            </p>

            <p>
              Alexandra Prenosil
              <br />
              Praxis für Naturheilkunde
              <br />
              Zollikerstrasse 19
              <br />
              8702 Zollikon
              <br />
              www.prenosil.ch
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <h2 className="h3 section-title">Realisation</h2>
            <p>
              The Edge Creative Solutions AG
              <br />
              Mainaustrasse 21-23
              <br />
              8008 Zürich
              <br />
              <a
                href="https://www.the-edge.ch"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                www.the-edge.ch
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ImpressumPage;
